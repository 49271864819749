<template>
  <a-modal
    :body-style="{
      borderRadius: '0 0 8px 8px',
      background: '#f2f4f8',
    }"
    :confirm-loading="!(!loading && !confirmLoading)"
    title="Добавить новое заведение"
    class="brand-create-modal"
    :visible="modalVisible"
    @cancel="resetForm"
    :closable="true"
    :footer="false"
    :width="1000"
  >
    <a-steps v-model:current="currentStep">
      <a-step disabled title="Шаг 1" description="Реквизиты компании">
        <template #icon>
          <audit-outlined />
        </template>
      </a-step>
      <a-step disabled title="Шаг 2" description="Информация о заведении">
        <template #icon>
          <form-outlined />
        </template>
      </a-step>
      <a-step disabled title="Шаг 3" description="Фискализация &nbsp;&nbsp;&nbsp;&nbsp; (по умолчанию)">
        <template #icon>
          <qrcode-outlined />
        </template>
      </a-step>
      <a-step disabled title="Шаг 4" description="Филиалы">
        <template #icon>
          <shop-outlined />
        </template>
      </a-step>
    </a-steps>
    <div v-if="formLoading" class="text-center py-5">
      <div class="card loading-card mt-5">
        <a-spin />
      </div>
    </div>
    <div v-else>
      <account-view
        v-show="currentStep === 0"
        :form="form"
        @success="accountSuccess"
        @back="currentStep = 0"
      />
      <info-view
        v-show="currentStep === 1"
        :form="form"
        @success="infoSuccess"
        @back="currentStep = 0"
      />
      <fiscal-view
        v-show="currentStep === 2"
        :form="form"
        @success="fiscalSuccess"
        @back="currentStep = 1"
      />
      <store-view
        :form="form"
        v-show="currentStep === 3"
        @success="register"
        :loading="loading"
        @back="currentStep = 2"
      />
    </div>
    <a-modal
      :width="800"
      :closable="false"
      v-model:visible="signModal"
      title="Подписать заявление"
      :confirm-loading="agreementLoading"
    >
      <div class="text-center w-100">
        <!--        <iframe :src="`https://view.officeapps.live.com/op/embed.aspx?src=${agreement}`" frameborder="0" style="width: 100%; height: 600px"></iframe>-->
        <iframe :src="`${agreement}#toolbar=0`" frameborder="0" style="width: 100%; height: 600px"></iframe>
      </div>
      <template #footer>
        <a-button key="back" @click="copy" :loading="copyLoading">Копировать ссылку</a-button>
        <a-button key="submit" type="primary" :loading="agreementLoading" @click="certificateModal = true">Подписать</a-button>
      </template>
      <a-list-item>
        <!--        <a-list-item-meta>-->
        <!--          <template #title>-->
        <!--            <a :href="agreement">Пользовательское соглашение</a>-->
        <!--          </template>-->
        <!--          <template #avatar>-->
        <!--            <a-avatar :size="50" shape="square" style="background-color: #eeeeee">-->
        <!--              <template #icon><FileWordTwoTone /></template>-->
        <!--            </a-avatar>-->
        <!--          </template>-->
        <!--        </a-list-item-meta>-->
      </a-list-item>
    </a-modal>
    <a-modal
      :width="300"
      v-model:visible="certificateModal"
      title="Выберите сертификат"
      ok-text="Подписать"
      cancel-text="Закрыть"
      @ok="sign"
      @cancel="signModal = false"
      :confirm-loading="agreementLoading"
    >
      <a-select
        @change="chooseCertificate"
        placeholder="Выберите сертификат"
        label-in-value
        style="width: 100%"
      >
        <a-select-option :disabled="cert.validTo < new Date() || ![cert['TIN'], cert['PINFL']].includes(tin)" v-for="cert in certificates" :key="cert.name" :value="cert.name">
          {{ getCertificateLabel(cert) }}
        </a-select-option>
      </a-select>
    </a-modal>
    <a-modal
      :width="500"
      v-model:visible="successModal"
      title="Успешно"
      :closable="false"
      :confirm-loading="agreementLoading"
    >
      Договор успешно подписан. Проверьте вашу почту {{ form.email }}, туда отправлен пароль для доступа в кабинет
      <template #footer>
        <a-button key="back" @click="download">Скачать договор</a-button>
      </template>
    </a-modal>
  </a-modal>
</template>

<script setup>
import { AuditOutlined, FormOutlined, QrcodeOutlined, ShopOutlined } from "@ant-design/icons-vue";
import AccountView from "@/views/auth/register/AccountView.vue";
import FiscalView from '@/views/auth/register/FiscalView.vue'
import StoreView from '@/views/auth/register/StoreView.vue'
import InfoView from "@/views/auth/register/InfoView.vue";
import { notification } from 'ant-design-vue'
import apiClient from "@/services/axios";
import EIMZO from '@/helpers/e-imzo'
import {ref, watch} from "vue";

const emit = defineEmits(['reload', 'hide']);
const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  claimId: {
    type: Number,
    default: 0,
  },
});

let
  certificate = ref(),
  form = ref({}),
  tin = ref(null),
  formKey = ref(0),
  adminId = ref(null),
  currentStep = ref(0),
  loading = ref(false),
  verified = ref(false),
  agreement = ref(null),
  certificates = ref([]),
  signModal = ref(false),
  agreementLink = ref(''),
  copyLoading = ref(false),
  formLoading = ref(false),
  successModal = ref(false),
  modalVisible = ref(false),
  confirmLoading = ref(false),
  certificateModal = ref(false),
  agreementLoading = ref(false);

const
  accountSuccess = account => {
    currentStep.value = 1
    form.value.certificate = account.value
    form.value.tin = account.tin
    form.value.fio = account.fio
    form.value.company = account.company
    form.value.position = account.position
    form.value.mfo = account.mfo
    form.value.account_no = account.account_no
    delete form.value['tax']
    saveApplication(form.value['claim_id'], form.value)
  },
  infoSuccess = info => {
    currentStep.value = 2
    form.value.name = info.name
    form.value.logo = info.logo
    form.value.background = info.background
    form.value.pos_type = info.pos_type
    form.value.wallet_type = info.wallet_type
    form.value.social = info.social
    delete form.value['tax']
    saveApplication(form.value['claim_id'], form.value)
  },
  fiscalSuccess = fiscal => {
    currentStep.value = 3
    form.value.tax = fiscal.tax ? 1 : 0
    form.value.spic = fiscal.spic
    form.value.code = fiscal.code
    form.value.product = fiscal.product
    form.value.price = fiscal.price
    saveApplication(form.value['claim_id'], form.value)
  },
  copy = async () => {
    copyLoading.value = true
    const link = document.createElement('textarea');
    link.value = agreementLink.value
    document.getElementsByTagName('body')[0].appendChild(link)
    link.select()
    link.setSelectionRange(0, 9999)
    document.execCommand('copy')
    link.remove()
    setTimeout(() => {
      notification.info({
        message: 'Ссылка скопирована'
      })
      copyLoading.value = false
    }, 500)
    // router.push({ name: 'login' })
  },
  register = stores => {
    loading.value = true
    apiClient.post('/auth/signup', {...form.value, stores}).then(response => {
      if (response?.data?.data?.['agreement']) {
        signModal.value = true
        agreement.value = response.data.data['agreement']
        adminId.value = response.data.data['id']
        agreementLink.value = 'https://boss.rhmt.uz/auth/sign/' + response.data.data['id'] + '/' + response.data.data['recipient']
      } else if (response?.data?.data?.['recipient']) {
        loading.value = true
        apiClient.get('/auth/agreement?user=' + response.data.data['id'] + '&account=' + response.data.data['recipient'])
          .then(res => {
            signModal.value = true
            agreement.value = res.data.data['agreement']
            tin.value = res.data.data.tin
            adminId.value = response.data.data['id']
            agreementLink.value = 'https://boss.rhmt.uz/auth/sign/' + response.data.data['id'] + '/' + response.data.data['recipient']
          }).finally(() => {
            loading.value = false
          })
      } else {
        notification.error({
          message: 'Произошла ошибка при создании договора'
        })
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    })
  },
  saveApplication = (id, data) => {
    apiClient.post(`/auth/application?claim=${id}`, data)
      .then(({data}) => {
        form.value.tax = data.data.tax
      })
      .catch(() => {
        // console.log(err)
      })
      .finally(() => {})
  },
  sign = async () => {
    if (!certificate.value)
      return;
    verified.value = false
    agreementLoading.value = true
    try {
      let id = ''
      const eimzo = new EIMZO()
      const selectedCertificate = await certificates.value.find(cert => cert.name === certificate.value)
      await eimzo.loadKey(selectedCertificate).then(key => id = key.id)
      const result = await eimzo.signPkcs7(selectedCertificate, id)
      // const token = await eimzo.getTimestampToken(result.signature_hex)
      // console.log(token)
      // console.log(result)
      apiClient.post('auth/eimzo-timestamp', {base64: result['pkcs7_64']}).then((res) => {
        apiClient.post('auth/eimzo-verify', {base64: res.data.data['pkcs7b64'], id: adminId.value}).then((res) => {
          if (res.data.data) {
            signModal.value = false
            successModal.value = true
            verified.value = true
          }
        }).catch(() => {
          verified.value = false
        }).finally(() => {
          agreementLoading.value = false
        })
      }).catch(() => {
        verified.value = false
      }).finally(() => {
        agreementLoading.value = false
      })
      agreementLoading.value = false
    } catch (e) {
      // console.log(e, 'try catch')
      verified.value = false
      agreementLoading.value = false
    }
  },
  download = () => {
    window.open(agreement.value, '_blank')
  },
  chooseCertificate = (cert) => {
    certificate.value = cert.key
  },
  getCertificateLabel = (cert) => {
    if (cert['O']) {
      return `${cert['O']} (${cert['TIN']})`
    }
    return `${cert['CN']} (${cert['PINFL']}`
  },
  resetForm = () => {
    modalVisible.value = false;
    emit('hide')
  };

watch(props, (props) => {
  modalVisible.value = props.show;
})
watch(modalVisible, (value) => {
  if (value) {
    formLoading.value = true
    apiClient.get(`/brand/claim?id=${props.claimId}`).then(({ data }) => {
      form.value = data?.data
      if (props.claimId) {
        formKey.value++
      }
    }).finally(async () => {
      formLoading.value = false
      currentStep.value = 0
      try {
        const eimzo = new EIMZO
        await eimzo.install()
        certificates.value = await eimzo.listAllUserKeys()
      } catch (e) {
        console.error(e)
      }
    })
    // form.value.id =
  }
})

</script>

<style scoped>
[data-vb-theme="default"] .card.loading-card {
  max-width: 420px;
  margin: 0 auto;
  padding: 30px;
}
</style>
