<template>
  <a-modal
    :body-style="{
      borderRadius: '0 0 8px 8px',
      background: '#f2f4f8',
    }"
    :title="`Подписать заявку #${claimId}`"
    :confirm-loading="loading"
    :visible="modalVisible"
    @cancel="resetForm"
    class="sign-modal"
    :closable="true"
    :footer="false"
    :width="500"
  >
    <div class="text-center w-100">
      <!--        <iframe :src="`https://view.officeapps.live.com/op/embed.aspx?src=${agreement}`" frameborder="0" style="width: 100%; height: 600px"></iframe>-->
      <iframe :src="`${claim?.agreement}#toolbar=0`" frameborder="0" style="width: 100%; height: 600px"></iframe>
      <div class="d-flex justify-content-center" style="gap: 10px">
        <a-button key="back" @click="copy" :loading="copyLoading">Копировать ссылку</a-button>
        <a-button key="submit" type="primary" :loading="agreementLoading" @click="certificateModal = true">Подписать</a-button>
      </div>
    </div>
      <!--    <a-list-item>-->
      <!--        <a-list-item-meta>-->
      <!--          <template #title>-->
      <!--            <a :href="agreement">Пользовательское соглашение</a>-->
      <!--          </template>-->
      <!--          <template #avatar>-->
      <!--            <a-avatar :size="50" shape="square" style="background-color: #eeeeee">-->
      <!--              <template #icon><FileWordTwoTone /></template>-->
      <!--            </a-avatar>-->
      <!--          </template>-->
      <!--        </a-list-item-meta>-->
      <!--    </a-list-item>-->
  </a-modal>
  <a-modal
    :width="300"
    v-model:visible="certificateModal"
    title="Выберите сертификат"
    ok-text="Подписать"
    cancel-text="Закрыть"
    @ok="sign"
    @cancel="signModal = false"
    :confirm-loading="agreementLoading"
  >
    <a-select
      @change="chooseCertificate"
      placeholder="Выберите сертификат"
      label-in-value
      style="width: 100%"
    >
      <a-select-option :disabled="cert.validTo < new Date() || ![cert['TIN'], cert['PINFL']].includes(claim?.tin)" v-for="cert in certificates" :key="cert.name" :value="cert.name">
        {{ getCertificateLabel(cert) }}
      </a-select-option>
    </a-select>
  </a-modal>
  <a-modal
    :width="500"
    v-model:visible="successModal"
    title="Успешно"
    :closable="false"
    :confirm-loading="agreementLoading"
  >
    Договор успешно подписан. Проверьте вашу почту {{ claim?.email }}, туда отправлен пароль для доступа в кабинет
    <template #footer>
      <a-button key="back" @click="download">Скачать договор</a-button>
    </template>
  </a-modal>
</template>

<script setup>
import { notification } from 'ant-design-vue'
import { onMounted, ref, watch } from 'vue'
import apiClient from '@/services/axios'
import EIMZO from '@/helpers/e-imzo'

const emit = defineEmits(['reload', 'hide']);
const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  claimId: {
    type: Number,
    default: 0,
  },
  claim: {
    type: Object,
    default: () => {}
  }
});

let
  certificate = ref(),
  tin = ref(null),
  adminId = ref(null),
  loading = ref(false),
  verified = ref(false),
  agreement = ref(null),
  certificates = ref([]),
  signModal = ref(true),
  agreementLink = ref(''),
  copyLoading = ref(false),
  successModal = ref(false),
  modalVisible = ref(false),
  certificateModal = ref(false),
  agreementLoading = ref(false);

const
  copy = () => {
    copyLoading.value = true
    const link = document.createElement('textarea');
    link.value = props.claim?.agreement
    document.getElementsByTagName('body')[0].appendChild(link)
    link.select()
    link.setSelectionRange(0, 9999)
    document.execCommand('copy')
    link.remove()
    setTimeout(() => {
      notification.info({
        message: 'Ссылка скопирована'
      })
      copyLoading.value = false
    }, 500)
  },
  register = stores => {
    loading.value = true
    apiClient.post('/auth/signup', {...props.claim, stores}).then(response => {
      if (response?.data?.data?.['agreement']) {
        signModal.value = true
        agreement.value = response.data.data['agreement']
        adminId.value = response.data.data['id']
        agreementLink.value = 'https://boss.rhmt.uz/auth/sign/' + response.data.data['id'] + '/' + response.data.data['recipient']
      } else if (response?.data?.data?.['recipient']) {
        loading.value = true
        apiClient.get('/auth/agreement?user=' + response.data.data['id'] + '&account=' + response.data.data['recipient'])
          .then(res => {
            signModal.value = true
            agreement.value = res.data.data['agreement']
            tin.value = res.data.data.tin
            adminId.value = response.data.data['id']
            agreementLink.value = 'https://boss.rhmt.uz/auth/sign/' + response.data.data['id'] + '/' + response.data.data['recipient']
          }).finally(() => {
            loading.value = false
          })
      } else {
        notification.error({
          message: 'Произошла ошибка при создании договора'
        })
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    })
  },
  sign = async () => {
    if (!certificate.value)
      return;
    verified.value = false
    agreementLoading.value = true
    try {
      let id = ''
      const eimzo = new EIMZO()
      const selectedCertificate = await certificates.value.find(cert => cert.name === certificate.value)
      await eimzo.loadKey(selectedCertificate).then(key => id = key.id)
      const result = await eimzo.signPkcs7(selectedCertificate, id)
      // const token = await eimzo.getTimestampToken(result.signature_hex)
      // console.log(token)
      // console.log(result)
      apiClient.post('auth/eimzo-timestamp', {base64: result['pkcs7_64']}).then((res) => {
        apiClient.post('auth/eimzo-verify', {base64: res.data.data['pkcs7b64'], id: props.claim?.['admin_id']}).then((res) => {
          if (res.data.data) {
            signModal.value = false
            successModal.value = true
            verified.value = true
          }
        }).catch(() => {
          verified.value = false
        }).finally(() => {
          agreementLoading.value = false
        })
      }).catch(() => {
        verified.value = false
      }).finally(() => {
        agreementLoading.value = false
      })
      agreementLoading.value = false
    } catch (e) {
      // console.log(e, 'try catch')
      verified.value = false
      agreementLoading.value = false
    }
  },
  download = () => {
    window.open(agreement.value, '_blank')
  },
  chooseCertificate = (cert) => {
    certificate.value = cert.key
  },
  getCertificateLabel = (cert) => {
    if (cert['O']) {
      return `${cert['O']} (${cert['TIN']})`
    }
    return `${cert['CN']} (${cert['PINFL']}`
  },
  resetForm = () => {
    modalVisible.value = false;
    emit('hide')
  };

watch(props, (props) => {
  modalVisible.value = props.show;
})

onMounted(async () => {
    try {
      const eimzo = new EIMZO
      await eimzo.install()
      certificates.value = await eimzo.listAllUserKeys()
    } catch (e) {
      console.error(e)
    }
})

</script>

<style scoped></style>
